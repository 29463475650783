<template>
  <div>
    <!-- <AppLoader v-if="isLoading" /> -->
    <!-- <AppLoader v-if="$store.state.globaLoading" :alternate="false" /> -->
    <div
      v-if="!(getSetupChildrens.length > 0)"
      class="bg-white fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-[99]"
    >
      <div class="h-full flex justify-center items-center flex-col">
        <div
          class="flex justify-center items-center text-[30px] font-bold py-9" 
          style="color: #01a4c0"
        >
          You have no children!
        </div>
        <RouterLink to="/guardian/children" replace
          ><span
            class="text-white px-[25px] py-[20px] rounded-[50px] font-semibold text-[20px] cursor-pointer"
            style="background: #56b897"
            >Add one</span
          ></RouterLink
        >
      </div>
    </div>

    <!-- :class="{
                'sticky px-[20px] ml-[-20px] left-0 bg-white shadow':
                    scrollPosition > 45,
            }" -->
    <div
      class="main top-[60px] z-[2]"
      style="margin-bottom: 35px; display: block; height: auto; width: 100%"
    >
      <div class="stories" style="padding-bottom: 20px; margin-top: 0">
        <div
          :class="{
            'stories__item--active': child.id == activeChild,
          }"
          :key="child.id"
          @click="
            childActivate(child.id, child.username, child.balance, child.avatar)
          "
          class="stories__item"
          style="position: relative"
          v-for="child in getSetupChildrens"
        >
          <button>
            <!-- <div class="stories__item-picture">
              <img :src="child.avatar" alt="Child" />
            </div> -->
            <span
              :class="{
                'stories__item-username--active': child.id == activeChild,
              }"
              class="stories__item-username mt-2"
              >{{ child.username }}</span
            >
          </button>
        </div>
      </div>
    </div>

    <div>
      <div v-if="getSetupChildrens.length > 0">
        <div v-if="missionsState == 'missions'">
          <!-- <div class="flex flex-wrap">
            
            <div id="new-mission" class="card">
              <div class="card-header pt-7 px-6">
                <button id="new-mission" @click="openBottom">
                  Create New Mission
                </button>
              </div>
              <div class="card-text" id="add-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#01a4c0"
                    d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2v-6Z"
                  />
                </svg>
              </div>
            </div>
            <div id="in-progress" class="card">
              <a href="#in_progress">
              <div class="card-header pt-7 px-6">
                <div class="pt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M12 20q-3.35 0-5.675-2.325T4 12q0-3.35 2.325-5.675T12 4q1.725 0 3.3.712T18 6.75V4h2v7h-7V9h4.2q-.8-1.4-2.188-2.2T12 6Q9.5 6 7.75 7.75T6 12q0 2.5 1.75 4.25T12 18q1.925 0 3.475-1.1T17.65 14h2.1q-.7 2.65-2.85 4.325T12 20Z"
                    />
                  </svg>
                </div>
                <div>{{ missionsInProgressCount }}</div>
              </div>
              <p class="card-text">In progress</p>
            </a>
            </div>
              <div id="pending" class="card">
                <a href="#pending_rewards">
              <div class="card-header pt-7 px-6">
                <div class="pt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M13 13h-2V7h2m0 10h-2v-2h2M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
                    />
                  </svg>
                </div>
                <div>{{ pendingRewardMissionsGetter.length }}</div>
              </div>
              <p class="card-text">Pending</p>
              </a>  
            </div>
            
            <div id="history" class="card">
              <a href="#history_link">
              <div class="flex justify-center header-history">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#01a4c0"
                    d="M12 21q-3.15 0-5.575-1.913T3.275 14.2q-.1-.375.15-.687t.675-.363q.4-.05.725.15t.45.6q.6 2.25 2.475 3.675T12 19q2.925 0 4.963-2.038T19 12q0-2.925-2.038-4.963T12 5q-1.725 0-3.225.8T6.25 8H8q.425 0 .713.288T9 9q0 .425-.288.713T8 10H4q-.425 0-.713-.288T3 9V5q0-.425.288-.713T4 4q.425 0 .713.288T5 5v1.35q1.275-1.6 3.113-2.475T12 3q1.875 0 3.513.713t2.85 1.924q1.212 1.213 1.925 2.85T21 12q0 1.875-.713 3.513t-1.924 2.85q-1.213 1.212-2.85 1.925T12 21Zm1-9.4l2.5 2.5q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275l-2.8-2.8q-.15-.15-.225-.337T11 11.975V8q0-.425.288-.713T12 7q.425 0 .713.288T13 8v3.6Z"
                  />
                </svg>
                <p id="pending_rewards"></p> 
              </div>
              <p>Mission history</p>
              </a>
            </div>
          </div> -->

          <section id="recent">
            <h2 class="sub-title" style="color: #f6a42c">Pending Missions</h2>
            <div
              class="recent_container"
              v-if="pendingRewardMissionsGetter.length > 0"
            >
              <div
                :class="{
                  expandedMission: expandedId == activity.id,
                }"
                :key="activity.id"
                @click="sheetMissionPendingRewardConfirm(activity)"
                class="activity_item border border-[#e3e3e3]"
                style="display: block; height: auto"
                v-for="activity in pendingRewardMissionsGetter"
              >
                <!-- {{activity}} -->

                <div class="activity_item__box">
                  <div class="activity_item__container">
                    <div>
                      <div
                        class="activity_item__title"
                        style="
                          word-wrap: break-word;
                          width: 300px;
                          display: flex;
                          justify-content: space-between;
                        "
                      >
                        <span class="inline-block">{{ activity.name }}</span>
                        <span class="inline-block"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="white"
                              d="M12.6 12L8 7.4L9.4 6l6 6l-6 6L8 16.6l4.6-4.6Z"
                            /></svg
                        ></span>
                      </div>
                      <div class="activity_item__description">
                        {{ activity.child.username }}
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    class="activity_item__amount flex items-center"
                    v-if="activity.reward_type == 'money'"
                  >
                    <span
                      class="text-[#56B897] text-[0.70rem] mr-[2px] font-bold"
                      >+</span
                    >
                    <img
                   ndar"
            v-model="calendarData"
          ></FunctionalCalendar>
  alt="Money"
                      class="w-[16px] h-[16px] mt-[-2px]"
                      src="../../assets/images/reward_money.svg"
                    />
                  </div>
                  <div
                    class="activity_item__amount flex items-center"
                    v-if="activity.reward_type == 'points'"
                  >
                    <span
                      class="text-[#58C1E1] text-[0.70rem] mr-[2px] font-bold"
                      >+</span
                    >
                    <img
                      alt="Points"
                      class="w-[13px] h-[13px] mt-[-2px]"
                      src="../../assets/images/reward_points.svg"
                    />
                  </div>
                  <div
                    class="activity_item__amount flex items-center"
                    v-if="activity.reward_type == 'wishlist'"
                  >
                    <span
                      class="text-[#EC6C6B] text-[0.70rem] mr-[2px] font-bold"
                      >+</span
                    >
                    <img
                      alt="Wishlist"
                      class="w-[13px] h-[13px]"
                      src="../../assets/images/reward_wishlist.svg"
                    />
                  </div>

                  <div
                    style="
                      display: flex;
                      position: absolute;
                      bottom: 0;
                      right: 0;
                    "
                  >
                    <span
                      class="text-[9px] bg-[#F8B465] text-white rounded-tl-[5px] py-[1px] px-[10px] font-semibold flex items-center justify-center"
                      >Pending Reward</span
                    >
                  </div> -->

                  <!--- starting confirm  mission ----->

                  <div
                    class="font-bold reward text-[#FFFFFF]"
                    v-if="activity.reward_type == 'money'"
                  >
                    <div>
                      + &pound;{{ formatPrice(activity.sum_money.toString()) }}
                    </div>
                  </div>
                  <div
                    class="font-bold reward text-[#FFFFFF]"
                    v-if="activity.reward_type == 'points'"
                  >
                    <div>
                      {{ activity.sum_points.toString() }}
                      Points
                    </div>
                  </div>
                  <div
                    class="font-bold reward text-[#FFFFFF]"
                    v-if="activity.reward_type == 'wishlist'"
                  >
                    <div>Product Reward</div>
                  </div>

                  <!-- <div v-if="Object.keys(activity).length > 0">
                    <div
                      style="
                        text-align: center;
                        margin-bottom: 10px;
                        font-weight: 600;
                        font-size: 12px;
                      "
                      v-if="activity.date_times_mission.length > 1"
                    >
                      Mission Stages
                    </div>
                    <div
                      class="extra"
                      style="margin-bottom: 15px"
                      v-if="activity.date_times_mission.length > 1"
                    >
                      <div
                        :key="index"
                        v-for="(stage, index) in activity.date_times_mission"
                      >
                        <div
                          class="bullet bl-green click"
                          flow="up"
                          tooltip="Stage Completed"
                          v-if="stage.status == 'completed'"
                        ></div>
                        <div
                          class="bullet bl-red"
                          flow="up"
                          tooltip="Stage Failed"
                          v-else-if="
                            stage.status == 'failed' ||
                            stage.status == 'expired'
                          "
                        ></div>
                        <div
                          @click="
                            openSheetConfirmMission(stage.missionDateTimeId)
                          "
                          class="bullet bl-orange"
                          flow="up"
                          tooltip="Pending Reward"
                          v-else-if="stage.status == 'pending_reward'"
                        ></div>
                        <div
                          class="bullet"
                          flow="up"
                          tooltip="Stage In Progress"
                          v-else-if="
                            stage.status == 'in_progress' &&
                            stage.start_date == currentDate
                          "
                        >
                          <img
                            height="29"
                            src="https://img.icons8.com/dusk/72/clock.png"
                            width="29"
                            alt=""
                          />
                        </div>
                        <div
                          class="bullet"
                          flow="up"
                          tooltip="Future Stage"
                          v-else
                        ></div>
                        <div
                          class="reactivateMission"
                          style="bottom: -50px"
                          v-if="stage.status == 'pending_reward'"
                        >
                          <span
                            @click="
                              openSheetConfirmMission(stage.missionDateTimeId)
                            "
                            style="
                              cursor: pointer;
                              background: rgb(82, 80, 84);
                              border-bottom-left-radius: 30px;
                            "
                            >Confirm</span
                          >
                          <span
                            @click="$emit('dismiss')"
                            style="
                              cursor: pointer;
                              background: rgb(177, 174, 174);
                              border-bottom-right-radius: 30px;
                            "
                            >Reject</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="w-[90%] mx-auto"
                    v-if="Object.keys(activity).length > 0"
                  >
                    <div
                      style="
                        font-weight: 400;
                        font-size: 13px;
                        text-align: center;
                        margin: 10px 0 0 0;
                      "
                      v-if="activity.date_times_mission.length > 1"
                    >
                      Stages completed
                      <span style="font-weight: bold"
                        >{{ stagesCompleted(activity.date_times_mission) }}
                        out of
                        {{ activity.date_times_mission.length }}
                      </span>
                      <div
                        style="font-size: 11px; margin-top: 5px"
                        v-if="
                          stagesCompleted(activity.date_times_mission) > 0 &&
                          activity.reward_type == 'money'
                        "
                      >
                        Partial reward amount:
                        <span style="font-weight: bold"
                          >&pound;{{
                            formatPrice(
                              partialReward(
                                activity.sum_money,
                                rewardPercent(
                                  stagesCompleted(activity.date_times_mission),
                                  activity.date_times_mission.length
                                )
                              )
                            )
                          }}</span
                        >
                      </div>
                    </div> -->

                  <!-- <div
                      style="
                        margin: 20px auto 20px auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                      v-if="
                        activity.date_times_mission.length > 1 &&
                        activity.reward_type == 'money'
                      "
                    >
                      <button
                        @click="giveReward(activity.id, activity.sum_money)"
                        class="bg-[#56B897] p-2 rounded-xl font-semibold shadow-lg text-white text-xs w-full ml-2"
                        style="font-family: var(--sans) !important"
                        v-if="stagesCompleted(activity.date_times_mission) > 0"
                      >
                        Give full reward
                      </button>
                      <button
                        @click="giveReward(activity.id, activity.sum_money)"
                        class="bg-[#FF8F8F] p-2 rounded-[100px] font-semibold shadow-lg text-white text-xs w-full ml-2"
                        style="font-family: var(--sans) !important"
                        v-else
                      >
                        Approve
                      </button>
                      <button
                        @click="
                          givePartialReward(activity.id, activity.sum_money)
                        "
                        class="bg-[#FF8F8F] p-2 rounded-[100px] font-semibold shadow-lg text-white text-xs w-full ml-2"
                        v-if="stagesCompleted(activity.date_times_mission) > 0"
                      >
                        Partial reward
                      </button>
                      <button
                        @click="
                          givePartialReward(activity.id, activity.sum_money)
                        "
                        class="bg-[#FF8F8F] p-2 rounded-[100px] font-semibold shadow-lg text-white text-xs w-full ml-2"
                        style="font-family: var(--sans) !important"
                        v-else
                      >
                        Not this time
                      </button>
                    </div> -->

                  <!-- <div
                      class="mx-auto mt-4 mb-8 flex justify-between items-center w-full"
                      v-else
                    >
                      <button
                        @click="
                          selectPendingAddress(
                            activity.id,
                            activity.product.price
                          )
                        "
                        class="bg-[#56B897] p-2 rounded-[100px] font-bold text-white text-s w-full ml-2"
                        style="font-family: var(--sans) !important"
                        v-if="activity.reward_type == 'wishlist'"
                      >
                        Approve
                      </button>
                      <button
                        @click="giveReward(activity.id, activity.sum_money)"
                        class="bg-[#56B897] p-2 rounded-[100px] font-bold text-white text-s w-full ml-2"
                        style="font-family: var(--sans) !important"
                        v-else
                      >
                        Approve
                      </button>
                      <button
                        @click="
                          givePartialReward(activity.id, activity.sum_money)
                        "
                        class="bg-[#EC6C6B] p-2 rounded-[100px] font-bold text-white text-s w-full ml-2"
                        style="font-family: var(--sans) !important"
                      >
                        Deny
                      </button>
                    </div> -->
                  <!-- </div> -->
                </div>
              </div>
            </div>
            <div v-else class="text-center text-sm pt-12 pb-4 text-[#C2C2C2]">
              There are no pending rewards!
            </div>
          </section>

          <br />
          <br />
          <p id="in_progress"></p>
          <!-- This p element is put here only to link to a specific part of the page -->
          <div class="mb-6 flex items-center justify-between">
            <h1 v-if="!viewAllMissions" class="sub-title">
              Missions in progress this week
            </h1>
            <h1 v-else class="sub-title">All in progress missions</h1>
            <button class="add-button" @click="openBottom">+</button>
          </div>

          <template v-if="!viewAllMissions">
            <div v-if="validateWeek" class="mb-8">
              <AppAdd
                :active="true"
                :activeInstructions="mission.description"
                :activeSum="mission.sum_money.toString()"
                :activeSumPoints="mission.sum_points.toString()"
                :activeTitle="mission.name"
                :description="mission.description"
                :avatar="childAvatar"
                :balance="guardianBalance.toString()"
                :key="index"
                :stages="mission.date_times_mission"
                :timeleft="Number(mission.milisecondsDateTime)"
                :type="mission.reward_type"
                @delete="deleteMission(mission.id)"
                @edit="missionDetails(mission, 'active')"
                @details="openMiniEdit(mission)"
                activeMissionType="Mini mission"
                class="element"
                color="blue"
                mission_type="mini"
                status="in_progress"
                title="Mini mission"
                v-for="(mission, index) in miniMissionsNewArrayGetter.filter(
                  (mission) =>
                    mission.date_times_mission[0].start_date >=
                      this.getWeekStart ||
                    mission.date_times_mission[0].start_date <= this.getWeekEnd
                )"
              />
            </div>
            <div v-else class="text-center text-sm pt-4 text-[#C2C2C2]">
              There are no missions this<br />
              week, add one now!
            </div>
            <div
              v-if="!viewAllMissions"
              class="text-s mb-4 text-center font-bold text-[#58C1E1]"
              @click="viewAllMissionsMethod"
            >
              <p class="view_more">View more</p>
            </div>
            <div
              v-else
              class="text-s mb-4 text-center font-bold text-[#58C1E1]"
              @click="viewAllMissionsMethod"
            >
              <p class="view_more">View less</p>
            </div>
          </template>
          <template v-else>
            <div v-if="miniMissionsNewArrayGetter.length > 0">
              <AppAdd
                :active="true"
                :description="mission.description"
                :activeInstructions="mission.description"
                :activeSum="mission.sum_money.toString()"
                :activeSumPoints="mission.sum_points.toString()"
                :activeTitle="mission.name"
                :avatar="childAvatar"
                :balance="guardianBalance.toString()"
                :key="index"
                :stages="mission.date_times_mission"
                :timeleft="Number(mission.milisecondsDateTime)"
                :type="mission.reward_type"
                @delete="deleteMission(mission.id)"
                @edit="missionDetails(mission, 'active')"
                @details="openMiniEdit(mission)"
                activeMissionType="Mini mission"
                class="element"
                color="blue"
                mission_type="mini"
                status="in_progress"
                title="Mini mission"
                v-for="(mission, index) in miniMissionsNewArrayGetter"
              />
            </div>
            <div v-else class="text-center text-sm pt-4 text-[#C2C2C2]">
              Add a mission to for them <br />
              to appear here!
            </div>
          </template>
        </div>

        <div v-if="viewAllMissions">
          <h1 class="sub-title">Mission History</h1>
          <p id="history_link"></p>
          <!-- This p element is put here only to link to a specific part of the page -->
          <div
            class="mt-6 mb-8"
            v-if="miniMissionsNewHistoryArrayGetter.length > 0"
          >
            <AppAdd
              :active="true"
              :description="mission.description"
              :activeInstructions="mission.description"
              :activeSum="mission.sum_money.toString()"
              :activeSumPoints="mission.sum_points.toString()"
              :activeTitle="mission.name"
              :avatar="childAvatar"
              :key="index"
              :stages="mission.date_times_mission"
              :timeleft="Number(mission.milisecondsDateTime)"
              :type="mission.reward_type"
              @delete="
                deleteMission(mission.date_times_mission.missionDateTimeId)
              "
              @details="missionDetails(mission, 'history')"
              @edit="openMiniEdit(mission)"
              activeMissionType="Mini mission"
              class="element"
              color="blue"
              mission_type="mini"
              status="in_progress"
              title="Mini mission"
              v-for="(mission, index) in miniMissionsNewHistoryArrayGetter"
            />
          </div>
          <div v-else class="text-center text-sm pt-4 text-[#C2C2C2]">
            Set a childs mission to<br />
            view the mission history!
          </div>
        </div>
        <div
          v-if="viewAllMissions"
          class="text-s mb-4 text-center font-bold text-[#58C1E1]"
          @click="viewAllMissionsMethod"
        >
          <p class="view_more">View less</p>
        </div>
      </div>
    </div>

    <Sheet :on-close="closeBottom" :show.sync="open">
      <StepNavigation :currentstep="currentstep" :steps="steps">
      </StepNavigation>
      <div class="sheetContent flex flex-col justify-between h-full">
        <div class="flex-1" v-if="currentstep == 1">
          <h1 class="text-center mt-12 mb-20 font-bold text-md leading-tight">
            Add a mission for
            <span style="color: #01a4c0">{{ childUsername }}</span>
          </h1>
          <!-- <img
            :src="childAvatar"
            alt="Avatar"
            class="mx-auto mt-4 w-[50px] h-[50px]"
          />
          <p class="text-center text-base mb-20 mt-1">
            {{ childUsername }}
          </p> -->
          <label
            class="text-left text-[16px] font-semibold mb-2 ml-1 block"
            for="missionInstructions"
            >Mission title
            <svg
              style="display: inline-block; margin-bottom: 10px"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ba4d4c"
                d="m5.825 22l1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625l7.2.625l-5.45 4.725L18.175 22L12 18.275L5.825 22Z"
              />
            </svg>
          </label>
          <AppInput
            :dice="false"
            label="mission_title"
            labelAlign="center"
            maxlength="20"
            placeholder="Title..."
            type="text"
            v-model="miniMissionTitle"
          />

          <label
            class="text-left text-[16px] font-semibold mb-2 ml-1 block"
            for="missionInstructions"
            >Mission description</label
          >
          <textarea
            class="global-textarea-style"
            cols="30"
            id="missionInstructions"
            name="mission_instructions"
            rows="5"
            placeholder="Description..."
            v-model="miniMissionInstructions"
          ></textarea>
        </div>

        <div class="addMissionExpl" v-if="currentstep == 2">
          <h5 class="text-center font-bold mt-12 mb-2 text-xl">
            Select a date!
          </h5>
          <!-- {{calendarData.selectedDates}}
          {{currentDate}} -->
          <p class="text-center font-semibold text-xs text-[#BABABA] px-6 mb-4">
            Select your mission's due date! This can be a single date, or, to
            make your mission a recurring task, select multiple different dates
          </p>
          <FunctionalCalendar
            :configs="calendarConfigs"
            :key="$store.state.componentKey"
            @choseDay="showDaySelected"
            ref="calendar"
            v-model="calendarData"
          ></FunctionalCalendar>

          <AppButton
            :color="confirmScheduleColor"
            :mini="true"
            :name="confirmScheduleName"
            @click="confirmSchedule"
            v-if="daily == true && hideSchedule == false"
          />
          <AppButton
            :color="confirmScheduleColor"
            :mini="true"
            :name="confirmScheduleName"
            @click="editSchedule"
            v-if="daily == true && hideSchedule == true"
          />
        </div>

        <div class="addMissionExpl" v-if="currentstep == 3">
          <div class="addMissionHoursStep">
            <div class="w-full">
              <h1 class="text-center mt-12 font-bold text-base leading-tight">
                Set a time for
                <span style="color: #01a4c0">{{ childUsername }}'s</span>
                mission
              </h1>

              <div
                class="button-wrap flex w-[95%] justify-between items-center mt-8 mx-auto"
              >
                <div class="w-full mr-1">
                  <input
                    :checked="missionHoursType"
                    @change="changeMissionHoursType(true)"
                    class="hidden radio-label"
                    id="yes-button"
                    name="accept-offers"
                    type="radio"
                  />
                  <label
                    class="button-label w-full font-bold text-xs"
                    for="yes-button"
                  >
                    <span>Free time</span>
                  </label>
                </div>
                <div class="w-full ml-1">
                  <input
                    :checked="!missionHoursType"
                    @change="changeMissionHoursType(false)"
                    class="hidden radio-label"
                    id="no-button"
                    name="accept-offers"
                    type="radio"
                  />
                  <label
                    class="button-label w-full font-bold text-xs"
                    for="no-button"
                  >
                    <span>Custom Time</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- <AppMissionScheduleOwnTime
            v-if="!missionHoursType"
            :disablePastHours="calendarData.selectedDates.filter(item => item.date == currentDate).length > 0"
            :endTime="mission_end_hour"
            :startTime="mission_start_hour"
            :disableMinutes="calendarData.selectedDates.filter(item => item.date == currentDate).length > 0"
            @endTime="getScheduleEndTimeCalendar"
            @startTime="getScheduleStartTimeCalendar"
          /> -->

          <MissionTimepickerPresent
            v-if="
              !missionHoursType &&
              calendarData.selectedDates.filter(
                (item) => item.date == currentDate
              ).length > 0
            "
            :disablePastHours="
              calendarData.selectedDates.filter(
                (item) => item.date == currentDate
              ).length > 0
            "
            :endTime="mission_end_hour"
            :startTime="mission_start_hour"
            :disableMinutes="
              calendarData.selectedDates.filter(
                (item) => item.date == currentDate
              ).length > 0
            "
            @endTime="getScheduleEndTimeCalendar"
            @startTime="getScheduleStartTimeCalendar"
          />

          <MissionTimepickerFuture
            v-if="
              !missionHoursType &&
              calendarData.selectedDates.filter(
                (item) => item.date == currentDate
              ).length == 0
            "
            :disablePastHours="
              calendarData.selectedDates.filter(
                (item) => item.date == currentDate
              ).length > 0
            "
            :endTime="mission_end_hour"
            :startTime="mission_start_hour"
            :disableMinutes="
              calendarData.selectedDates.filter(
                (item) => item.date == currentDate
              ).length > 0
            "
            @endTime="getScheduleEndTimeCalendar"
            @startTime="getScheduleStartTimeCalendar"
          />

          <!------- End custom time selector ----->
        </div>

        <div class="addMissionExpl" v-if="currentstep == 4">
          <h1 class="text-center mt-12 font-bold text-xl leading-tight">
            Select what type of<br />
            reward you’d like to give
          </h1>
          <!-- <img
            :src="childAvatar"
            alt="Avatar"
            class="mx-auto mt-4 w-[50px] h-[50px]"
          /> -->
          <p
            class="text-center text-xl fond-bold mb-12 mt-3"
            style="color: #01a4c0; font-weight: 800"
          >
            {{ childUsername }}
          </p>

          <div class="form__options">
            <div class="form__answer">
              <input
                :checked="isChecked"
                @click="changeMissionRewardType('points')"
                class="missionCheckbox"
                id="match_1"
                name="match"
                type="radio"
                value="guy"
              />
              <label
                class="lbl text-[13px] leading-tight p-3 rounded-lg border-none flex items-center justify-between"
                :class="{
                  'reward-type-background__points':
                    missionRewardType === 'points',
                }"
                for="match_1"
                style="display: flex"
              >
                <div>
                  Sonik Pocket<br />
                  Points
                  <p class="text-xs" style="font-size: 12px">+25</p>
                </div>
                <div
                  class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
                >
                  <img
                    alt=""
                    class="w-3 h-3 mt-[2px]"
                    src="../../assets/images/mission-rewards/reward_points.svg"
                  />
                </div>
              </label>
            </div>
            <!-- :disabled="$store.state.balance <= 0 || !modulrStatement" -->
            <div class="form__answer" style="margin: 0 10px">
              <input
                :checked="isChecked"
                :disabled="$store.state.balance <= 0"
                @click="changeMissionRewardType('money')"
                class="missionCheckbox"
                id="match_2"
                name="match"
                type="radio"
                value="girl"
              />
              <!-- $store.state.balance <= 0 || !modulrStatement -->
              <label
                :style="[$store.state.balance <= 0 ? { opacity: '.5' } : {}]"
                class="lbl text-[13px] leading-tight p-3 rounded-lg border-none bg-[#EFEFEF] flex items-center justify-between"
                :class="{
                  'reward-type-background__money':
                    missionRewardType === 'money',
                }"
                for="match_2"
                style="display: flex"
              >
                <div>
                  Money
                  <p class="text-xs" style="font-size: 11px">
                    Available balance
                  </p>
                  <p
                    style="margin-top: -1px; font-weight: bold; font-size: 12px"
                  >
                    &pound;{{ formatPrice(guardianBalance) }}
                  </p>
                </div>
                <div
                  class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
                >
                  <img
                    alt=""
                    class="w-3 h-3 mt-[4px]"
                    src="../../assets/images/mission-rewards/reward_money.svg"
                  />
                </div>
              </label>
            </div>

            <div class="form__answer">
              <input
                :checked="isChecked"
                :disabled="
                  missionWishlistGetter.length == 0 ||
                  $store.state.balance <= 0 ||
                  !modulrStatement
                "
                @click="
                  changeMissionRewardType('wishlist');
                  fetchMissionWishlistAction(activeChild);
                "
                class="missionCheckbox"
                id="match_3"
                name="match"
                type="radio"
                value="cat"
              />

              <label
                :style="[
                  missionWishlistGetter.length == 0 ||
                  $store.state.balance <= 0 ||
                  !modulrStatement
                    ? { opacity: '.5' }
                    : {},
                ]"
                class="lbl text-[13px] leading-tight p-3 rounded-lg border-none bg-[#EFEFEF] flex items-center justify-between"
                :class="{
                  'reward-type-background__wishlist':
                    missionRewardType === 'wishlist',
                }"
                for="match_3"
                style="display: flex"
              >
                <div>
                  Wishlist
                  <p class="text-xs leading-tight" style="font-size: 11px">
                    Select an item from <br />
                    {{ childUsername }}’s wishlist!
                  </p>
                </div>
                <div
                  class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
                >
                  <img
                    alt=""
                    class="w-3 h-3 mt-[4px]"
                    src="../../assets/images/mission-rewards/reward_wishlist.svg"
                  />
                </div>
              </label>
            </div>
          </div>

          <p
            v-if="missionRewardType === 'points'"
            class="text-[0.65rem] text-center px-8 mt-4"
          >
            {{ childUsername }} will recieve 25 of Sonik Pocket Points, which
            they can use on our virtual store
          </p>

          <p
            v-if="missionRewardType === 'money'"
            class="text-[0.65rem] text-center px-8 mt-4"
          >
            Reward {{ childUsername }} your child with real money which they can
            save towards a 'savings goal' or use within our internal, vetted,
            eCommerce store!
          </p>

          <div
            class="addMissionRewardType flex items-center justify-center flex-col"
          >
            <div class="">
              <div
                class="flex items-center justify-center mb-[10px] text-[13px] mt-[30px] font-bold"
              >
                Reward visibility
                <div
                  class="w-[10px] h-[10px] bg-[#FF4E78] rounded-full ml-2"
                ></div>
              </div>
            </div>

            <toggle-button
              :color="{
                checked: '#56B897',
                unchecked: '#EC6C6B',
              }"
              :font-size="11"
              :height="25"
              :labels="{ checked: 'Yes', unchecked: 'No' }"
              :sync="true"
              :value="isRewardVisible"
              :width="65"
              @change="changeRewardVisibility()"
            />
          </div>

          <div v-if="missionRewardType == 'money'">
            <!-- <p class="text-[0.65rem] text-center px-8 mt-4">
              Reward your child with real money which they can save towards a
              'savings goal' or use within our internal, vetted, eCommerce
              store!
            </p> -->

            <p class="text-center text-[10px] font-light mt-6 mb-2">
              parents who make this mission like to give...
            </p>

            <div class="flex items-center justify-between overflow-x-auto">
              <button
                :class="{
                  selectedAmount: amountSelected == amount.id,
                }"
                :key="amount.id"
                @click="toggleAmount(amount.sum, amount.id)"
                class="bg-[#F9F9F9] border border-[#E7E7E7] rounded p-1 text-sm text-[#56B897] mr-1 min-w-[75px]"
                v-for="amount in predefinedAmounts"
              >
                +&pound;{{ amount.sum }}
              </button>
            </div>
          </div>
          <AppInput
            :min="1"
            :placeholder="`Enter a custom amount for ${childUsername}`"
            @input="inputValidation"
            class="missions__Money"
            type="number"
            v-if="missionRewardType == 'money'"
            v-model="miniMissionMoney"
          />

          <div
            style="margin-top: -20px; font-size: 12px; color: #ff4f79"
            v-if="noFundsError"
          >
            {{ noFundsMessage }}
          </div>

          <div
            style="padding: 10px; margin-top: 30px"
            v-if="
              missionRewardType == 'wishlist' &&
              missionWishlistGetter.length > 0
            "
          >
            <div class="text-center text-sm font-light">
              Select an item from {{ childUsername }}’s wishlist!
            </div>

            <div
              style="height: 200px; overflow: auto; margin-top: 10px"
              v-bind:class="{
                wishlistActive: wishlistToogleButtonState,
              }"
            >
              <div
                :key="index"
                @change="showWishlistItem"
                class="wishlist_answer"
                v-for="(item, index) in missionWishlistGetter"
              >
                <input
                  :id="item.id"
                  :name="item.id"
                  :value="item"
                  type="radio"
                  v-model="selectedWishlistItem"
                />
                <label :for="item.id" class="wishlist_label">
                  <img
                    :src="resolveImage(item.default_picture_link)"
                    alt=""
                    height="50"
                    width="50"
                  />
                  <div style="margin-left: 20px">
                    <p
                      class="wishlist_title"
                      style="
                        display: -webkit-box;
                        height: 20px;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 14px;
                      "
                    >
                      {{ item.name }}
                    </p>
                    <p class="wishlist_price">
                      Price: &pound;{{ formatPrice(item.price) }}
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div
            class="p-4 text-xs text-center"
            v-else-if="missionRewardType == 'wishlist'"
          >
            Your child has not added any wishlist items. Please remind
            {{ childUsername }} to add items to their wishlist.
          </div>

          <AppError
            :message="addError"
            style="margin-top: 30px; margin-bottom: -30px"
          />
        </div>

        <StepControls
          class="mt-8 pb-4"
          :calendar="checkPassStep"
          :missionDetails="validateStepMissionDetails"
          :currentstep="currentstep"
          :finishStepStatus="finishStepValidation"
          :key="index"
          :step="step"
          :stepCustom="true"
          :stepcount="steps.length"
          :selectedDates="calendarData"
          @step-change="stepChanged"
          @step-submit="addMiniMissionSubmit"
          style="width: 100%"
          v-for="(step, index) in steps"
        >
        </StepControls>
      </div>
    </Sheet>

    <Sheet
      :on-close="closeSheetConfirmMission"
      :show.sync="sheetConfirmMission"
    >
      <div
        style="
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 20px;
        "
      >
        Do you want to confirm this mission as done?
      </div>
      <div
        style="
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <button
          @click="rejectMission"
          style="
            background: var(--main);
            border: none;
            padding: 15px;
            color: white;
            border-radius: 5px;
            margin-right: 10px;
            width: 100%;
            font-weight: 600;
          "
        >
          Nope
        </button>
        <button
          @click="confirmStage"
          style="
            background: var(--main);
            border: none;
            padding: 15px;
            color: white;
            border-radius: 5px;
            width: 100%;
            font-weight: 600;
          "
        >
          Confirm
        </button>
      </div>
    </Sheet>
    <Sheet
      :on-close="closeSheetMissionDetails"
      :show.sync="missionDetailsSheet"
    >
      <div>
        <div class="font-bold mb-6" style="font-size: 25px; color: #01a4c0">
          {{ childUsername }}
        </div>

        <div class="px-2 text-xl font-bold">Mission Titles</div>
        <div class="text-m mt-3 px-3">
          {{ sheetMissionDetails.name }}
        </div>
        <div class="px-2 text-xl font-bold mt-6">Mission Description</div>
        <div class="text-m mt-3 px-3">
          {{ sheetMissionDetails.description }}
        </div>

        <div v-if="Object.keys(sheetMissionDetails).length > 0">
          <div class="px-2 text-xl font-bold mt-6">Mission Date</div>
          <div class="text-m mt-3 px-3">
            {{
              monthToName(sheetMissionDetails.date_times_mission[0].start_date)
            }}
          </div>
          <div class="px-2 text-xl font-bold mt-6">Mission Time</div>
          <div class="text-m mt-3 px-3 inline">
            <div class="text-m mt-3 px-3 inline">
              {{ missionStartHourSplit }}
            </div>
            <div class="text-m mt-3 px-3 inline">to</div>
            <div class="text-m mt-3 px-3 inline">
              {{ missionEndHourSplit }}
            </div>
          </div>
        </div>

        <div>
          <div class="px-2 text-xl font-bold mt-6">Reward</div>
          <div
            v-if="sheetMissionDetails.reward_type == 'money'"
            class="text-m font-semibold mt-3 px-3"
            style="color: #01a4c0"
          >
            + &pound;{{ formatPrice(sheetMissionDetails.sum_money.toString()) }}
          </div>
          <div
            v-if="sheetMissionDetails.reward_type == 'points'"
            class="text-m font-semibold mt-3 px-3"
            style="color: #01a4c0"
          >
            {{ sheetMissionDetails.sum_points.toString() }} Points
          </div>
          <div
            v-if="sheetMissionDetails.reward_type == 'wishlist'"
            class="text-m font-semibold mt-3 px-3"
            style="color: #01a4c0"
          >
            Product Reward
          </div>

          <div class="px-2 text-xl font-bold mt-6">Status</div>
          <div class="text-m font-semibold mt-3 px-3">
            {{
              sheetMissionDetails.date_times_mission
                ? sheetMissionDetails.date_times_mission[0].status
                : ""
            }}
          </div>

          <div v-if="Object.keys(sheetMissionDetails).length > 0">
            <div
              v-if="sheetMissionDetails.date_times_mission.length > 1"
              class="px-2 text-xl font-bold mt-6 mb-4"
            >
              Mission Stages ({{
                sheetMissionDetails.date_times_mission.length
              }})
              <!-- {{sheetMissionDetails.date_times_mission}} -->
              <!-- {{currentDate}} -->
              <!-- {{new Date().toISOString().slice(0, 10)}} -->
            </div>
            <div
              v-if="sheetMissionDetails.date_times_mission.length > 1"
              class="extra"
              style="margin: 0 auto 40px auto; flex-wrap: wrap"
            >
              <div
                v-for="(stage, index) in sheetMissionDetails.date_times_mission"
                :key="index"
                style="margin-bottom: 10px"
              >
                <!-- {{ stage }} -->
                <div
                  v-if="stage.status == 'completed'"
                  class="bullet bl-green click"
                  tooltip="Stage Completed"
                  flow="up"
                  style="color: white; font-size: 11px; font-weight: 600"
                >
                  {{ index + 1 }}
                </div>
                <div
                  v-else-if="
                    stage.status == 'failed' || stage.status == 'expired'
                  "
                  class="bullet bl-red"
                  tooltip="Stage Failed"
                  flow="up"
                  style="color: white; font-size: 11px; font-weight: 600"
                >
                  {{ index + 1 }}
                </div>
                <div
                  v-else-if="stage.status == 'pending_reward'"
                  class="bullet bl-orange"
                  tooltip="Pending Reward"
                  flow="up"
                  style="color: white; font-size: 11px; font-weight: 600"
                >
                  {{ index + 1 }}
                </div>
                <div
                  v-else-if="
                    stage.status == 'in_progress' &&
                    stage.start_date == currentDate
                  "
                  class="bullet"
                  tooltip="Stage In Progress"
                  flow="up"
                >
                  <img
                    src="https://img.icons8.com/dusk/72/clock.png"
                    width="29"
                    height="29"
                    alt="clock"
                  />
                </div>
                <div
                  v-else
                  class="bullet"
                  tooltip="Future Stage"
                  flow="up"
                  style="color: white; font-size: 11px; font-weight: 600"
                >
                  {{ index + 1 }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          v-if="sheetMissionDetailsType == 'active'"
        >
          <button
            @click="deleteMission(sheetMissionDetails.id)"
            style="
              background: var(--main);
              border: none;
              padding: 8px 15px;
              color: white;
              border-radius: 5px;
              margin-right: 10px;
              width: 100%;
            "
          >
            <svg
              fill="none"
              height="20"
              stroke="#fff"
              viewBox="0 0 24 24"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </button>
          <button
            @click="openMiniEdit(sheetMissionDetails)"
            style="
              background: var(--main);
              border: none;
              padding: 8px 15px;
              color: white;
              border-radius: 5px;
            "
          >
            <svg
              fill="none"
              height="20"
              stroke="#fff"
              viewBox="0 0 24 24"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </Sheet>

    <Sheet :on-close="closeSheetMissionEdit" :show.sync="sheetMissionEdit">
      <div slot="title">Mission Details</div>
      <div style="width: 100%">
        <div
          class="text-center font-bold mb-6"
          style="font-size: 1.7em; color: #01a4c0"
        >
          {{ childUsername }}
        </div>
        <div>
          <AppInput
            :dice="false"
            id="missionName"
            placeholder="Edit the mission title"
            style="width: 100%"
            name="Mission name"
            v-model="editMiniMissionTitle"
          />
          <label
            for="missionDescription"
            style="font-size: 12px; font-weight: 600"
            >Description</label
          >
          <textarea
            class="appInput"
            id="missionDescription"
            style="
              width: 100%;
              margin: 5px 0 0 0 !important;
              height: 80px !important;
              border-radius: 20px !important;
              padding: 20px !important;
              border: 1px solid #dedede;
              color: #7a615a;
              font-size: 14px;
              background-color: #ebebeb !important;
            "
            v-model="editMiniMissionInstructions"
          ></textarea>

          <!-- <div class="missionHoursContainer mt-4">
            <div class="header_container">
              <div class="wishlist_header">
                <div class="text-[12px] font-semibold">Dates</div>
                <p class="text-xs">Current selected dates for this mission</p>
              </div>
            </div>

            <div class="mt-4">
              <div
                v-if="editMissionDates.length > 0"
                class="flex flex-row flex-wrap"
              >
                <div :key="index" v-for="(date, index) in editMissionDates">
                  <div
                    class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-[#806157] h-[30px]"
                    style="background: #efefef; border: 1px solid #efefef"
                  >
                    <div
                      class="text-xs font-normal leading-none max-w-full flex-initial mr-1"
                    >
                      {{ parseDate(date.date, "dd-mm-yy") }}
                    </div>
                    <div
                      class="flex flex-auto flex-row-reverse"
                      @click="removeHourItem(index)"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-x cursor-pointer hover:text-indigo-400 rounded-full w-4 h-4 ml-2"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <button
                v-if="!editCalendarToogleButtonState"
                @click="editShowCalendar"
                class="mt-3 text-xs p-1 mx-auto rounded mt-1 mb-4"
                style="color: var(--main)"
              >
                Select More Dates
              </button>
            </div>
          </div>

          <FunctionalCalendar
            :configs="editCalendarConfigs"
            :key="$store.state.componentKey"
            id="editCalendar"
            class="mt-4"
            v-if="editCalendarToogleButtonState"
            v-model="editCalendarData"
          >
            <template v-slot:footer>
              <div class="footer">
                <button
                  class="text-xs text-white py-1 px-2 mx-auto rounded"
                  style="background: var(--main)"
                  @click="editCloseCalendar"
                >
                  Save new dates
                </button>
              </div>
            </template>
          </FunctionalCalendar>

          <div
            class="addMissionRewardType flex items-center justify-center flex-col"
          >
            <div class="">
              <div
                class="flex items-center justify-center mb-[10px] text-[13px] mt-[10px] font-bold"
              >
                All day mission?
              </div>
            </div>

            <toggle-button
              :color="{
                checked: '#56B897',
                unchecked: '#EC6C6B',
              }"
              :font-size="11"
              :height="25"
              :labels="{ checked: 'Yes', unchecked: 'No' }"
              :sync="true"
              :value="editMissionHoursType"
              :width="65"
              @change="editChangeMissionHoursType()"
            />
          </div>

          <div class="missionHours">
            <div style="width: 100%" v-if="!editMissionHoursType">
              <AppMissionSchedule
                :endTime="edit_mission_end_hour"
                :startTime="edit_mission_start_hour"
                @endTime="editGetScheduleEndTimeCalendar"
                @startTime="editGetScheduleStartTimeCalendar"
                id="editMissionHours"
                style="width: 100%; margin: 0 auto 50px auto"
              />
            </div>
          </div> -->

          <div class="flex mx-auto justify-center items-center mb-4 mt-6">
            <div class="text-center text-xs mr-5">Reward Type:</div>
            <div
              v-if="editGetRewardType == 'money'"
              class="activity_item__amount flex items-center"
            >
              <span class="text-[#56B897] text-[0.70rem] mr-[2px] font-bold"
                >+ {{ editMiniMissionMoney }}
              </span>
              <img
                src="../../assets/images/reward_money.svg"
                class="w-[16px] h-[16px] mt-[-2px]"
                alt="Money"
              />
            </div>
            <div
              v-if="editGetRewardType == 'points'"
              class="activity_item__amount flex items-center"
            >
              <span class="text-[#58C1E1] text-[0.70rem] mr-[2px] font-bold"
                >+ 25
              </span>
              <img
                src="../../assets/images/reward_points.svg"
                class="w-[13px] h-[13px] mt-[-2px]"
                alt="Points"
              />
            </div>
            <div
              v-if="editGetRewardType == 'wishlist'"
              class="activity_item__amount flex items-center"
            >
              <span class="text-[#EC6C6B] text-[0.70rem] mr-[2px] font-bold"
                >+</span
              >
              <img
                src="../../assets/images/reward_wishlist.svg"
                class="w-[13px] h-[13px]"
                alt="Wishlist"
              />
            </div>
          </div>

          <div class="form__options" style="margin-bottom: 20px">
            <div class="form__answer">
              <input
                :checked="editGetRewardType == 'points'"
                @click="editChangeMissionRewardType('points')"
                class="missionCheckbox"
                id="match_4"
                name="match"
                type="radio"
                value="guy"
              />
              <label
                class="lbl text-[13px] leading-tight p-3 rounded-lg border-none bg-[#EFEFEF] flex items-center justify-between"
                for="match_4"
              >
                <div :class="{ active_reward: editGetRewardType === 'points' }">
                  Sonik Pocket<br />
                  Points
                  <p class="text-xs" style="font-size: 12px">+25</p>
                </div>
                <div
                  class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
                >
                  <img
                    alt=""
                    class="w-3 h-3 mt-[2px]"
                    src="../../assets/images/mission-rewards/reward_points.svg"
                  />
                </div>
              </label>
            </div>

            <div class="form__answer" style="margin: 0 10px">
              <input
                :checked="editGetRewardType == 'money'"
                :disabled="$store.state.balance <= 0 || !modulrStatement"
                @change="editChangeMissionRewardType('money')"
                class="missionCheckbox"
                id="match_5"
                name="match"
                type="radio"
                value="girl"
              />
              <label
                :style="[
                  $store.state.balance <= 0 || !modulrStatement
                    ? { opacity: '.5' }
                    : {},
                ]"
                class="lbl text-[13px] leading-tight p-3 rounded-lg border-none bg-[#EFEFEF] flex items-center justify-between"
                for="match_5"
              >
                <div :class="{ active_reward: editGetRewardType === 'money' }">
                  Money
                  <p class="text-xs" style="font-size: 11px">
                    Available balance
                  </p>
                  <p
                    style="margin-top: -1px; font-weight: bold; font-size: 12px"
                  >
                    &pound;{{ formatPrice(guardianBalance) }}
                  </p>
                </div>
                <div
                  class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
                >
                  <img
                    alt=""
                    class="w-3 h-3 mt-[4px]"
                    src="../../assets/images/mission-rewards/reward_money.svg"
                  />
                </div>
              </label>
            </div>

            <div class="form__answer">
              <input
                :checked="editGetRewardType == 'wishlist'"
                @change="editChangeMissionRewardType('wishlist')"
                class="missionCheckbox"
                id="match_6"
                name="match"
                type="radio"
                value="cat"
              />
              <label
                class="lbl text-[13px] leading-tight p-3 rounded-lg border-none bg-[#EFEFEF] flex items-center justify-between"
                for="match_6"
              >
                <div
                  :class="{ active_reward: editGetRewardType === 'wishlist' }"
                >
                  Wishlist
                  <p class="text-xs leading-tight" style="font-size: 11px">
                    Select an item from <br />
                    {{ childUsername }}’s wishlist!
                  </p>
                </div>
                <div
                  class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
                >
                  <img
                    alt=""
                    class="w-3 h-3 mt-[4px]"
                    src="../../assets/images/mission-rewards/reward_wishlist.svg"
                  />
                </div>
              </label>
            </div>
          </div>

          <div
            style="padding: 10px; margin-top: 30px"
            v-if="
              editGetRewardType == 'wishlist' &&
              missionWishlistGetter.length > 0
            "
          >
            <div class="header_container">
              <div class="wishlist_header">
                <div class="wishlist_header__title">Wishlist items</div>
              </div>
            </div>

            <div
              style="height: 200px; overflow: auto; margin-top: 10px"
              v-bind:class="{
                wishlistActive: wishlistToogleButtonState,
              }"
            >
              <div
                :key="index"
                class="wishlist_answer"
                v-for="(item, index) in missionWishlistGetter"
              >
                <input
                  :checked="editSelectedWishlistItem == item.id"
                  :id="item.id * 3"
                  :value="item.id"
                  @change="showWishlistItem(item.id)"
                  name="wishlistItem"
                  type="radio"
                />
                <label :for="item.id * 3" class="wishlist_label">
                  <img
                    :src="resolveImage(item.default_picture_link)"
                    alt=""
                    height="50"
                    width="50"
                  />
                  <div style="margin-left: 20px">
                    <p
                      class="wishlist_title"
                      style="
                        display: -webkit-box;
                        height: 20px;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 14px;
                      "
                    >
                      {{ item.name }}
                    </p>
                    <p class="text-sm">&pound;{{ formatPrice(item.price) }}</p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div
            class="p-4 text-xs text-center"
            v-else-if="editGetRewardType == 'wishlist'"
          >
            Your child has not added any wishlist items. Please remind
            {{ childUsername }} to add items to their wishlist.
          </div>

          <div
            class="text-xs font-light mt-4"
            v-if="editGetRewardType == 'money'"
          >
            Reward Amount
          </div>
          <AppInput
            @input="inputValidation"
            class="missions__Money"
            :min="1"
            pattern="[0-9\.]+"
            placeholder="How much £ will receive?"
            style="margin-top: 5px !important; margin-bottom: 0 !important"
            type="number"
            v-if="editGetRewardType == 'money'"
            v-model="editMiniMissionMoney"
          />

          <div class="addMissionRewardType mt-4">
            <div>
              <div class="mb-[10px] text-[15px] mt-[10px] font-bold">
                Reward visible?
              </div>
            </div>

            <toggle-button
              :color="{
                checked: '#56B897',
                unchecked: '#EC6C6B',
              }"
              :font-size="11"
              :height="25"
              :labels="{ checked: 'Yes', unchecked: 'No' }"
              :sync="true"
              :value="editIsRewardVisible"
              :width="65"
              @change="editChangeRewardVisibility()"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-14">
        <button
          @click="deleteMission(sheetMissionDetails.id)"
          class="bg-[#D76261] rounded-[100px] py-3 px-4 text-white text-s font-bold"
        >
          Delete Mission
        </button>
        <button
          @click="editMiniMission"
          class="bg-[#01a4c0] rounded-[100px] py-3 px-4 text-white text-s font-bold"
        >
          Apply Changes
        </button>
      </div>
    </Sheet>

    <Sheet :on-close="closeSheetPendingReward" :show.sync="sheetPendingReward">
      <div
        style="
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          margin-bottom: 20px;
        "
      >
        Missions (Pending Rewards)
      </div>
      <div v-if="getSetupChildrens.length > 0">
        <div v-if="miniMissionsNewArrayGetter.length > 0">
          <div
            style="width: 100%; margin-bottom: 0"
            v-if="miniMissionsNewPendingRewardArrayGetter.length > 0"
          >
            <AppAdd
              :active="true"
              :activeInstructions="mission.description"
              :activeSum="mission.sum_money.toString()"
              :activeSumPoints="mission.sum_points.toString()"
              :activeTitle="mission.name"
              :balance="guardianBalance.toString()"
              :key="index"
              :stages="mission.date_times_mission"
              :timeleft="Number(mission.milisecondsDateTime)"
              :type="mission.reward_type"
              @delete="deleteMission(mission.id)"
              @details="pendingRewardConfirm(mission)"
              @edit="openMiniEdit(mission)"
              mission_type="mini"
              status="in_progress"
              style="
                margin-left: 2px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
                  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
              "
              v-for="(
                mission, index
              ) in miniMissionsNewPendingRewardArrayGetter"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet
      :on-close="closeSheetPendingMissionConfirm"
      :show.sync="showSheetMissionPendingRewardConfirm"
      v-if="sheetMissionDetails.date_times_mission"
    >
      <div
        style="
          color: #f6a42c;
          margin-bottom: 30px;
          font-size: 16px;
          font-weight: 500;
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          class="inline-block mb-1 mr-1"
        >
          <path
            fill="#f6a42c"
            d="M13 13h-2V7h2m0 10h-2v-2h2M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
          />
        </svg>
        Pending
      </div>
      <div style="font-weight: bold; word-wrap: break-word">
        <!-- {{ sheetMissionDetails.date_times_mission[0].status }} -->
        Mission is
        <span
          style="font-weight: bold"
          v-if="
            sheetMissionDetails.date_times_mission[0].status == 'pending_reward'
          "
          >Pending Reward</span
        >
        <span
          style="color: #01a4c0; text-transform: capitalize; font-weight: bold"
          v-else
          >{{ sheetMissionDetails.date_times_mission[0].status }}</span
        >
      </div>
      <div style="font-weight: 400; margin-top: 5px; font-size: 14px">
        {{ sheetMissionDetails.description }}
      </div>
      <div style="font-weight: bold; margin-top: 30px; word-wrap: break-word">
        {{ sheetMissionDetails.name }}
      </div>
      <div style="font-weight: 400; font-size: 14px" class="mt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          class="inline-block mb-1 mr-1"
        >
          <path
            fill="#806157"
            d="M8 14q-.425 0-.713-.288T7 13q0-.425.288-.713T8 12q.425 0 .713.288T9 13q0 .425-.288.713T8 14Zm4 0q-.425 0-.713-.288T11 13q0-.425.288-.713T12 12q.425 0 .713.288T13 13q0 .425-.288.713T12 14Zm4 0q-.425 0-.713-.288T15 13q0-.425.288-.713T16 12q.425 0 .713.288T17 13q0 .425-.288.713T16 14ZM5 22q-.825 0-1.413-.588T3 20V6q0-.825.588-1.413T5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588T21 6v14q0 .825-.588 1.413T19 22H5Zm0-2h14V10H5v10Z"
          />
        </svg>
        <template v-if="sheetMissionDetails.date_times_mission">
          {{ monthToName(sheetMissionDetails.date_times_mission[0].end_date) }}
        </template>
      </div>
      <div style="font-weight: 400; font-size: 14px; width: 500px" class="mt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          class="inline-block mb-1 mr-1"
        >
          <path
            fill="#806157"
            d="m14.55 16.55l1.4-1.425l-2.95-2.95V8h-2v5l3.55 3.55ZM11 6h2V4h-2v2Zm7 7h2v-2h-2v2Zm-7 7h2v-2h-2v2Zm-7-7h2v-2H4v2Zm8 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"
          />
        </svg>
        <div class="inline-block">
          <div
            class="inline-block"
            v-if="
              sheetMissionDetails.date_times_mission[0].start_time ===
                '00:00:00' &&
              sheetMissionDetails.date_times_mission[0].end_time === '23:59:00'
            "
          >
            Free time
          </div>
          <div v-else class="inline-block">
            {{ sheetMissionDetails.date_times_mission[0].end_time }}
          </div>
        </div>
      </div>
      <div
        style="
          font-weight: 800;
          margin-top: 30px;
          font-size: 36px;
          color: #f6a42c;
        "
        v-if="sheetMissionDetails.reward_type == 'money'"
      >
        + &pound;{{ formatPrice(sheetMissionDetails.sum_money.toString()) }}
      </div>
      <div
        style="
          font-weight: 800;
          font-size: 36px;
          margin-top: 30px;
          color: #f6a42c;
        "
        v-if="sheetMissionDetails.reward_type == 'points'"
      >
        {{ sheetMissionDetails.sum_points.toString() }} Points
      </div>
      <div
        style="
          font-weight: 800;
          margin-top: 30px;
          font-size: 36px;
          color: #f6a42c;
        "
        v-if="sheetMissionDetails.reward_type == 'wishlist'"
      >
        Product Reward
      </div>
      <div v-if="Object.keys(sheetMissionDetails).length > 0">
        <div
          style="
            text-align: center;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 12px;
          "
          v-if="sheetMissionDetails.date_times_mission.length > 1"
        >
          Mission Stages
        </div>
        <div
          class="extra"
          v-if="sheetMissionDetails.date_times_mission.length > 1"
        >
          <div
            :key="index"
            v-for="(stage, index) in sheetMissionDetails.date_times_mission"
          >
            <div
              class="bullet bl-green click"
              flow="up"
              tooltip="Stage Completed"
              v-if="stage.status == 'completed'"
            ></div>
            <div
              class="bullet bl-red"
              flow="up"
              tooltip="Stage Failed"
              v-else-if="stage.status == 'failed' || stage.status == 'expired'"
            ></div>
            <div
              @click="openSheetConfirmMission(stage.missionDateTimeId)"
              class="bullet bl-orange"
              flow="up"
              tooltip="Pending Reward"
              v-else-if="stage.status == 'pending_reward'"
            ></div>
            <div
              class="bullet"
              flow="up"
              tooltip="Stage In Progress"
              v-else-if="
                stage.status == 'in_progress' && stage.start_date == currentDate
              "
            >
              <img
                height="29"
                src="https://img.icons8.com/dusk/72/clock.png"
                width="29"
                alt="clock"
              />
            </div>
            <div class="bullet" flow="up" tooltip="Future Stage" v-else></div>
            <div
              class="reactivateMission"
              style="bottom: -50px"
              v-if="stage.status == 'pending_reward'"
            >
              <span
                @click="openSheetConfirmMission(stage.missionDateTimeId)"
                style="
                  cursor: pointer;
                  background: rgb(82, 80, 84);
                  border-bottom-left-radius: 30px;
                "
                >Confirm</span
              >
              <span
                @click="$emit('dismiss')"
                style="
                  cursor: pointer;
                  background: rgb(177, 174, 174);
                  border-bottom-right-radius: 30px;
                "
                >Reject</span
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="Object.keys(sheetMissionDetails).length > 0">
        <div
          style="
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            margin: 10px 0 15px 0;
          "
          v-if="sheetMissionDetails.date_times_mission.length > 1"
        >
          Stages completed
          <span style="font-weight: bold"
            >{{ stagesCompleted(sheetMissionDetails.date_times_mission) }}
            out of
            {{ sheetMissionDetails.date_times_mission.length }}
          </span>
          <div
            style="font-size: 13px; margin-top: 5px"
            v-if="
              stagesCompleted(sheetMissionDetails.date_times_mission) > 0 &&
              sheetMissionDetails.reward_type == 'money'
            "
          >
            Partial reward amount:
            <span style="font-weight: bold"
              >&pound;{{
                formatPrice(
                  partialReward(
                    sheetMissionDetails.sum_money,
                    rewardPercent(
                      stagesCompleted(sheetMissionDetails.date_times_mission),
                      sheetMissionDetails.date_times_mission.length
                    )
                  )
                )
              }}</span
            >
          </div>
        </div>

        <div
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 25px;
          "
          v-if="
            sheetMissionDetails.date_times_mission.length > 1 &&
            sheetMissionDetails.reward_type == 'money'
          "
        >
          <button
            @click="
              giveReward(sheetMissionDetails.id, sheetMissionDetails.sum_money)
            "
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 33px;
              width: 100%;
              font-weight: 800;
              font-size: 16px;
            "
            v-if="stagesCompleted(sheetMissionDetails.date_times_mission) > 0"
          >
            Give full reward
          </button>
          <button
            @click="
              giveReward(sheetMissionDetails.id, sheetMissionDetails.sum_money)
            "
            class="giveRewardButton"
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 33px;
              width: 100%;
              font-weight: 800;
              font-size: 16px;
            "
            v-else
          >
            Give reward
          </button>
          <button
            @click="
              givePartialReward(
                sheetMissionDetails.id,
                sheetMissionDetails.sum_money
              )
            "
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 33px;
              width: 100%;
              font-weight: 800;
              font-size: 16px;
            "
            v-if="stagesCompleted(sheetMissionDetails.date_times_mission) > 0"
          >
            Partial reward
          </button>
          <button
            @click="
              givePartialReward(
                sheetMissionDetails.id,
                sheetMissionDetails.sum_money
              )
            "
            style="
              background: #ec6c6b;
              border: none;
              padding: 15px;
              color: white;
              border-radius: 33px;
              width: 100%;
              font-weight: 800;
              margin-top: 10px;
              font-size: 16px;
            "
            v-else
          >
            Failed
          </button>
        </div>

        <div
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 25px;
          "
          v-else
        >
          <button
            @click="
              selectPendingAddress(
                sheetMissionDetails.id,
                sheetMissionDetails.product.price
              )
            "
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 33px;
              width: 100%;
              font-weight: 800;
              font-size: 16px;
            "
            v-if="sheetMissionDetails.reward_type == 'wishlist'"
          >
            Success
          </button>
          <button
            @click="
              giveReward(sheetMissionDetails.id, sheetMissionDetails.sum_money)
            "
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 33px;
              width: 100%;
              font-weight: 800;
              margin-top: 10px;
              font-size: 16px;
            "
            v-else
          >
            Success
          </button>
          <button
            @click="
              givePartialReward(
                sheetMissionDetails.id,
                sheetMissionDetails.sum_money
              )
            "
            style="
              background: #ec6c6b;
              border: none;
              padding: 15px;
              color: white;
              border-radius: 33px;
              width: 100%;
              font-weight: 800;
              margin-top: 10px;
              font-size: 16px;
            "
          >
            Failed
          </button>
        </div>
      </div>
    </Sheet>

    <Sheet
      :on-close="closeSheetPendingAddressModal"
      :show.sync="sheetPendingAddressModal"
    >
      <div v-if="addresses">
        <div
          v-for="address in addresses"
          :key="address.id"
          @click="selectDeliveryAddress(address.id)"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
          "
        >
          {{ address.address }}
        </div>
      </div>
    </Sheet>
  </div>
</template>

<script>
import Sheet from "../../components/Sheet";
import { mapActions, mapGetters } from "vuex";
import config from "../../config";
import axios from "axios";
import status from "../../utils/statusCode";
// import AppLoader from "../../components/AppLoader";
import { FunctionalCalendar } from "vue-functional-calendar";
import StepNavigation from "../../components/wizard/StepNavigation";
import StepControls from "../../components/wizard/StepControls";
import swal from "sweetalert";
import MissionTimepickerPresent from "../../components/MissionTimepickerPresent.vue";
import MissionTimepickerFuture from "../../components/MissionTimepickerFuture.vue";

const predefinedAmountsObject = [
  {
    id: 1,
    sum: 3,
  },
  {
    id: 2,
    sum: 4,
  },
  {
    id: 3,
    sum: 5,
  },
  {
    id: 4,
    sum: 7.5,
  },
  {
    id: 5,
    sum: 10,
  },
  {
    id: 6,
    sum: 12.5,
  },
];

export default {
  components: {
    AppButton: () =>
      import(/* webpackChunkName: "AppButton" */ "../../components/AppButton"),
    AppError: () =>
      import(/* webpackChunkName: "AppError" */ "../../components/AppError"),
    AppAdd: () =>
      import(/* webpackChunkName: "AppAdd" */ "../../components/AppAdd"),
    AppInput: () =>
      import(/* webpackChunkName: "AppInput" */ "../../components/AppInput"),
    // AppMissionSchedule: () =>
    //   import(
    //     /* webpackChunkName: "AppMissionSchedule" */ "../../components/AppMissionSchedule"
    //   ),
    // AppMissionScheduleOwnTime: () =>
    //   import(
    //     /* webpackChunkName: "AppMissionScheduleOwnTime" */ "../../components/AppMissionScheduleOwnTime"
    //   ),
    // AppMissionDetails: () =>
    //   import(
    //     /* webpackChunkName: "AppMissionDetails" */ "../../components/AppMissionDetails"
    //   ),
    // AppLoader,
    Sheet,
    FunctionalCalendar,
    StepNavigation,
    StepControls,
    MissionTimepickerPresent,
    MissionTimepickerFuture,
  },
  data() {
    return {
      multipleDaysSelected: false,
      scrollPosition: null,
      predefinedAmounts: predefinedAmountsObject,
      amountSelected: null,
      expandedId: null,
      viewAllMissions: false,
      sheetMissionDetailsType: null,
      showSheetMissionPendingRewardConfirm: false,
      confirmMissionId: null,
      sheetConfirmMission: false,
      sheetMissionEdit: false,
      missionDetailsSheet: false,
      sheetPendingReward: false,
      editMiniMissionChildId: null,
      open: false,
      childUsername: null,
      childAvatar: null,
      noFundsError: false,
      noFundsMessage: "",
      passStep: false,
      finishStepVal: false,
      additional: [],
      currentstep: 1,
      step: 1,
      active: 1,
      steps: [
        {
          id: 1,
          title: "1",
          icon_class: "fa fa-map-marker",
        },
        {
          id: 2,
          title: "2",
          icon_class: "fa fa-folder-open",
        },
        {
          id: 3,
          title: "3",
          icon_class: "fa fa-paper-plane",
        },
        {
          id: 4,
          title: "4",
          icon_class: "fa fa-paper-plane",
        },
      ],
      /////////////////////////////
      disabledDates: {
        to: new Date(Date.now() + -1 * 24 * 3600 * 1000), // Disable all calendar dates up to specific date
      },
      missionRewardType: "",
      selectedAddressId: null,
      pendingMissionId: null,
      pendingMissionSum: null,
      sheetPendingAddressModal: false,
      selectPendingAddressState: false,
      selectPendingAddressMessage: "",
      missionsState: "missions",
      modalHasError: false,
      modal1: false,
      modal3: false,
      miniMissionTitle: "",
      daily: false,
      custom: false,
      isLoading: true,
      isRewardVisible: true,
      miniMissionInstructions: "",
      miniMissionMoney: "",
      activeChild: 0,
      error: "",
      miniMission: {},
      addError: "",
      missionTitle: "",
      missionInstructions: "",
      getRewardType: "",
      isChecked: false,
      time_conditions: [],
      hideSchedule: true,
      confirmScheduleName: "Apply schedule",
      confirmScheduleColor: "blue",
      pastMissionsModal: false,
      finalTimesConditions: [],
      missionHoursType: true,
      ownCustomTime: true,
      mission_start_hour: "00:00",
      mission_end_hour: "23:59",
      dateCalendar: {},
      calendarData: {},
      calendarConfigs: {
        dateFormat: "dd-mm-yyyy",
        isMultipleDatePicker: true,
        hiddenElements: ["leftAndRightDays"],
        disabledDates: ["beforeToday"],
        dayNames: ["M", "T", "W", "T", "F", "S", "S"],
      },
      selectedWishlistItem: null,
      wishlistToogleButtonState: false,
      wishlistToogleButtonText: "Done",
      calendarToogleButtonState: true,
      calendarToogleButtonText: "Select more dates",
      editMiniModal: false,
      editMiniMissionTitle: "",
      editMiniMissionInstructions: "",
      editMiniMissionMoney: "",
      editDateCalendar: {},
      editMissionHoursType: true,
      editGetRewardType: "",
      editSelectedWishlistItem: null,
      editIsRewardVisible: false,
      editMissionDates: {},
      edit_mission_start_hour: "00:00",
      edit_mission_end_hour: "23:59",
      edit_mission_start_hour_switch: "00:00",
      edit_mission_end_hour_switch: "23:59",
      editMissionShowInputs: true,
      editCalendarToogleButtonState: false,
      editCalendarToogleButtonText: "Select more dates",
      componentKey: 0,
      editCalendarData: {},
      editCalendarConfigs: {
        dateFormat: "dd-mm-yyyy",
        isMultipleDatePicker: true,
        hiddenElements: ["leftAndRightDays"],
        disabledDates: ["beforeToday"],
      },
      sheetMissionDetails: [],
      //Object of the mission which is in pending reward with type wishlist and needs to have an address selected
      pendingMissionWishlistReward: {
        mission_id: null,
        mission_sum: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getSetupChildrens",
      "getSetupMissions",
      "pastMissions",
      "miniMissions",
      "guardianBalance",
      "miniMissionsNewGetter",
      "miniMissionsNewArrayGetter",
      "miniMissionsNewHistoryArrayGetter",
      "miniMissionsNewPendingRewardArrayGetter",
      "missionWishlistGetter",
      "addresses",
      "modulrStatusGetter",
      "pendingRewardMissionsGetter",
    ]),
    validateWeek() {
      return (
        this.miniMissionsNewArrayGetter.filter(
          (mission) =>
            mission.date_times_mission[0].start_date >= this.getWeekStart ||
            mission.date_times_mission[0].start_date <= this.getWeekEnd
        ).length > 0
      );
    },
    currentDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      return [day, month, year].join("-");
    },
    modulrStatement() {
      switch (this.modulrStatusGetter) {
        case 2:
          return true;
        case 3:
          return true;
        case 6:
          return true;
        default:
          return false;
      }
      // return this.modulrStatusGetter != 0 || this.modulrStatusGetter != 3 || this.modulrStatusGetter != 6;
    },
    checkPassStep() {
      return this.passStep;
    },
    validateStepMissionDetails() {
      return this.step === 1 && this.miniMissionTitle == "";
    },
    finishStepValidation() {
      return this.finishStepVal;
    },
    miniComplete() {
      if (this.missionRewardType == "money") {
        return this.miniMissionTitle.length > 2 &&
          this.miniMissionMoney != 0 &&
          Object.keys(this.calendarData).length > 0 &&
          Object.keys(this.calendarData.selectedDates).length > 0
          ? "blue"
          : "";
      } else if (this.missionRewardType == "points") {
        return this.miniMissionTitle.length > 2 &&
          Object.keys(this.calendarData).length > 0 &&
          Object.keys(this.calendarData.selectedDates).length > 0
          ? "blue"
          : "";
      } else if (this.missionRewardType == "wishlist") {
        return this.miniMissionTitle.length > 2 &&
          Object.keys(this.calendarData).length > 0 &&
          Object.keys(this.calendarData.selectedDates).length > 0 &&
          this.selectedWishlistItem != null
          ? "blue"
          : "";
      } else {
        return "";
      }
    },
    miniCompleteEdit() {
      if (this.editGetRewardType == "money") {
        return this.editMiniMissionTitle.length > 2 &&
          this.editMiniMissionMoney != 0
          ? "blue"
          : "";
      } else if (this.editGetRewardType == "wishlist") {
        return this.editMiniMissionTitle.length > 2 &&
          this.missionWishlistGetter.length > 0
          ? "blue"
          : "";
      } else if (this.editMiniMissionTitle.length > 2) {
        return "blue";
      } else {
        return "";
      }
    },
    getWeekStart() {
      let curr = new Date();
      let firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));

      let month = "" + (firstday.getMonth() + 1);
      let day = "" + firstday.getDate();
      let year = firstday.getFullYear();

      return [day, month, year].join("-");
    },
    getWeekEnd() {
      let curr = new Date();
      let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));

      let month = "" + (lastday.getMonth() + 1);
      let day = "" + lastday.getDate();
      let year = lastday.getFullYear();

      return [day, month, year].join("-");
    },
    missionStartHourSplit() {
      var hour =
        this.sheetMissionDetails.date_times_mission[0].start_time.split(":");
      return `${hour[0]}:${hour[1]}`;
    },
    missionEndHourSplit() {
      var hour =
        this.sheetMissionDetails.date_times_mission[0].end_time.split(":");
      return `${hour[0]}:${hour[1]}`;
    },
    missionsInProgressCount() {
      return this.miniMissionsNewArrayGetter.filter(
        (mission) => mission.date_times_mission[0].status === "in_progress"
      ).length;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    Promise.all([
      this.fetchChildren(),
      this.fetchUser(),
      this.fetchBalance(),
      this.fetchPendingRewardMissions(),
    ]).then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions([
      "TOAST",
      "fetchMiniMissionsNew",
      "addMiniMission",
      "editMission",
      "deleteMiniMission",
      "pastMissionConfirm",
      "pastMissionDecline",
      "reactivateMission",
      "dismissMission",
      "fetchBalance",
      "fetchMissionWishlistAction",
      "fetchProfile",
      "doGiveRewardAction",
      "dontGiveRewardAction",
      "fetchUser",
      "fetchPendingRewardMissions",
    ]),
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    monthToName(str) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]; //array of months
      const day = str.split("-")[0]; //get day from date
      const month = str.split("-")[1]; //get month from date
      const year = str.split("-")[2]; //get year from date
      return `${day} ${months[month - 1]} ${year}`; //return day and month
    },
    viewAllMissionsMethod() {
      this.viewAllMissions = !this.viewAllMissions;
    },
    toggleAmount(sum, id) {
      this.amountSelected = id;
      this.miniMissionMoney = sum;
    },
    sheetMissionPendingRewardConfirm(activity) {
      this.openSheetPendingMissionConfirm();
      console.log(activity);
      this.sheetMissionDetails = activity;
    },
    convertTime(time) {
      var hourEnd = time.indexOf(":");
      var H = +time.substr(0, hourEnd);
      var h = H % 12 || 12;
      var ampm = H < 12 ? "am" : "pm";
      return h + time.substr(hourEnd, 3) + ampm;
    },
    stagesCompleted(array) {
      return array.filter((item) => {
        return item.status === "completed";
      }).length;
    },
    rewardPercent(value, total) {
      var calcPercent = function (v, t) {
        return (100 * v) / t;
      };

      return calcPercent(value, total);
    },
    partialReward(price, percent) {
      return (price * percent) / 100;
    },
    missionDetails(mission, type) {
      this.sheetMissionDetails = mission;
      this.sheetMissionDetailsType = type;
      this.openSheetMissionDetails();
    },
    pendingRewardConfirm(mission) {
      this.sheetMissionDetails = mission;
      this.openSheetPendingMissionConfirm();
    },
    confirmStage() {
      this.pastMissionConfirm(this.confirmMissionId);
      this.sheetConfirmMission = false;
      this.fetchChildren();
    },
    rejectMission() {
      this.pastMissionDecline(this.confirmMissionId);
      this.sheetConfirmMission = false;
      this.fetchChildren();
    },
    clearAddMission() {
      this.additional = [];
      this.miniMissionTitle = "";
      this.miniMissionInstructions = "";
      this.miniMissionMoney = "";
      this.dateCalendar = {};
      this.calendarData = {};
      this.missionHoursType = true;
      this.ownCustomTime = true;
      this.getRewardType = "";
      this.missionRewardType = "";
      this.selectedWishlistItem = null;
      this.isRewardVisible = true;
      this.wishlistToogleButtonState = false;
      setTimeout(() => {
        this.currentstep = 1;
      }, 500);
    },
    closeBottom() {
      this.open = false;
      this.clearAddMission();
    },
    openBottom() {
      this.open = true;
    },
    openSheetMissionDetails() {
      this.missionDetailsSheet = true;
    },
    closeSheetMissionDetails() {
      this.missionDetailsSheet = false;
    },
    openSheetMissionEdit() {
      this.sheetMissionEdit = true;
    },
    closeSheetMissionEdit() {
      this.sheetMissionEdit = false;
      this.editCalendarToogleButtonState = false;
    },
    openSheetConfirmMission(id) {
      this.closeSheetMissionDetails();
      this.confirmMissionId = id;
      this.sheetConfirmMission = true;
    },
    closeSheetConfirmMission() {
      this.sheetConfirmMission = false;
    },
    openSheetPendingReward() {
      this.fetchProfile();
      this.sheetPendingReward = true;
    },
    closeSheetPendingReward() {
      this.sheetPendingReward = false;
    },
    openSheetPendingMissionConfirm() {
      // this.closeSheetPendingReward();
      this.showSheetMissionPendingRewardConfirm = true;
    },
    closeSheetPendingMissionConfirm() {
      this.showSheetMissionPendingRewardConfirm = false;
    },
    async fetchChildren() {
      const token = localStorage.getItem("guardian_token");
      const userId = localStorage.getItem("guardian_user_id");
      const family = localStorage.getItem("guardian_family_id");

      await axios
        .post("/children", {
          userId: userId,
          token: token,
          family_id_check: family,
        })
        .then((response) => {
          const { statusCode } = response.data;
          if (status.success(statusCode)) {
            this.$store.commit("FETCH_SETUP_CHILDRENS", response.data.data);
            if (response.data.data.length > 0) {
              localStorage.setItem(
                "guardian_setup_active_child_id",
                response.data.data[0].users_id
              );

              this.childActivate(
                response.data.data[0].users_id,
                this.getSetupChildrens[0].username,
                this.getSetupChildrens[0].balance,
                this.getSetupChildrens[0].avatar
              );
              this.$store.state.globaLoading = false;
            }
          } else if (status.invalidToken(statusCode)) {
            this.deleteStorage();
          } else if (status.error(statusCode)) {
            this.TOAST(
              "Something went wrong! Please contact our support team."
            );
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    forceRerender() {
      this.$store.state.componentKey += 1;
    },
    check_one(val) {
      this.additional = [];
      if (val === 1) {
        this.miniMissionTitle = "Walk the dog";
        this.miniMissionInstructions = "Walk the dog once a day";
      } else if (val === 2) {
        this.miniMissionTitle = "Wash the dishes";
        this.miniMissionInstructions = "Wash the dishes after dinner";
      } else if (val === 3) {
        this.miniMissionTitle = "Take out the trash";
        this.miniMissionInstructions = "Take out the trash every morning";
      } else {
        this.miniMissionTitle = "";
        this.miniMissionInstructions = "";
      }
    },
    closeSheetPendingAddressModal() {
      this.sheetPendingAddressModal = false;
    },
    selectDeliveryAddress(address) {
      this.sheetPendingAddressModal = false;

      const missionDataObject = {
        address_id: address,
        mission_id: this.pendingMissionWishlistReward.mission_id,
      };

      this.doGiveRewardAction(missionDataObject);
    },
    selectPendingAddress(id, sum) {
      this.pendingMissionWishlistReward.mission_id = id;
      this.pendingMissionWishlistReward.mission_sum = sum;
      this.sheetPendingAddressModal = true;
    },
    giveReward(id, sum) {
      let missionSum = parseInt(sum);

      const missionData = {
        address_id: this.selectedAddressId,
        mission_id: id,
      };

      if (this.type == "money") {
        if (this.guardianBalance > 0 && this.guardianBalance >= missionSum) {
          this.doGiveRewardAction(missionData);
          this.closeSheetPendingMissionConfirm();
        } else {
          this.closeSheetPendingMissionConfirm();
          this.TOAST(
            "You do not have enough money to spend on this mission. Please add money to your account!"
          );
        }
      } else {
        this.doGiveRewardAction(missionData);
        this.closeSheetPendingMissionConfirm();
      }
    },
    givePartialReward(id, sum) {
      let missionSum = parseInt(sum);
      if (this.type == "money") {
        if (this.balance > 0 && this.balance >= missionSum) {
          this.dontGiveRewardAction(id);
          this.closeSheetPendingMissionConfirm();
        } else {
          this.closeSheetPendingMissionConfirm();
          this.TOAST(
            "You do not have enough money to spend on this mission. Please add money to your account!"
          );
        }
      } else {
        this.dontGiveRewardAction(id);
        this.closeSheetPendingMissionConfirm();
      }
    },
    changeMissionsState(state) {
      this.fetchMiniMissionsNew(this.activeChild);
      this.missionsState = state;
    },
    stepChanged(step) {
      //   if (step === 2 && Object.keys(this.calendarData).length > 0) {
      //     if (this.calendarData.selectedDates.length > 0) {
      //       this.passStep = false;
      //       this.currentstep = step;
      //     } else {
      //       this.passStep = true;
      //     }
      //   } else {
      //     this.passStep = true;
      //   }

      this.currentstep = step;

      //   if (step !== 2) {
      //     this.currentstep = step;
      //     this.passStep = false;
      //   }

      //   if(this.multipleDaysSelected) {
      //     this.currentstep = 4;
      //   } else {
      //     this.currentstep = step;
      //   }

      // console.log("this.currentstep", this.currentstep);
      // console.log(this.multipleDaysSelected);

      //   if (step === 4 && Object.keys(this.calendarData).length > 0) {
      //     if (this.calendarData.selectedDates.length > 1) {
      //       this.currentstep = 2;
      //       console.log("cacat")
      //     }
      //   }

      //   console.log("this.currentstep from top", this.currentstep);

      //   if (step === 3 && Object.keys(this.calendarData).length > 0) {
      //     if (this.calendarData.selectedDates.length > 1) {
      //       this.currentstep = 4;
      //       console.log("this.currentstep from statement", this.currentstep);
      //     }
      //   } else {
      //     this.currentstep = step;
      //   }

      //   console.log("this.currentstep from outside", this.currentstep);
    },
    showDaySelected() {
      if (this.currentstep == 2 && this.calendarData.selectedDates.length > 1) {
        this.multipleDaysSelected = true;
      } else {
        this.multipleDaysSelected = false;
      }

      if (this.currentstep == 2 && this.calendarData.selectedDates.length > 0) {
        this.passStep = false;
      } else {
        this.passStep = true;
      }
    },
    removeHourItem(index) {
      this.editMissionDates.splice(index, 1);
    },
    fetchMissionWishlist() {
      const activeChild = localStorage.getItem("activeChild");
      this.fetchMissionWishlistAction(activeChild);
    },
    editShowCalendar() {
      this.editCalendarToogleButtonState = true;
      this.editMissionShowInputs = false;
    },
    editCloseCalendar() {
      this.editCalendarToogleButtonState = false;
      this.editMissionShowInputs = true;
    },
    parseDate(dateStr, format) {
      const regex = format
        .toLocaleLowerCase()
        .replace(/\bd+\b/, "(?<day>\\d+)")
        .replace(/\bm+\b/, "(?<month>\\d+)")
        .replace(/\by+\b/, "(?<year>\\d+)");

      const parts = new RegExp(regex).exec(dateStr) || {};
      const { year, month, day } = parts.groups || {};
      const finalDate =
        parts.length === 4 ? new Date(year, month - 1, day) : undefined;

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const finday = finalDate.getDate();
      const finmonthIndex = finalDate.getMonth();
      const finyear = finalDate.getFullYear();

      return `${finday} ${monthNames[finmonthIndex]} ${finyear}`;
    },
    returnDate() {
      const datesArray = [];
      const startHour = this.mission_start_hour;
      const endHour = this.mission_end_hour;

      this.calendarData.selectedDates.forEach(function (value) {
        const datesFormat = {
          start_datetime: value.date + " " + startHour,
          end_datetime: value.date + " " + endHour,
        };
        datesArray.push(datesFormat);
      });

      this.dateCalendar = datesArray;
    },
    editReturnDate() {
      const datesArray = [];
      const startHour = this.mission_start_hour;
      const endHour = this.mission_end_hour;

      this.editCalendarData.selectedDates.forEach(function (value) {
        const datesFormat = {
          start_datetime: value.date + " " + startHour,
          end_datetime: value.date + " " + endHour,
        };
        datesArray.push(datesFormat);
      });

      this.editDateCalendar = datesArray;
    },
    showWishlistItem(item) {
      this.editSelectedWishlistItem = item;
    },
    inputValidation(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }

      if (this.miniMissionMoney > this.$store.state.balance) {
        alert("Not enough money");
      }
    },
    changeMissionRewardType(type) {
      this.miniMissionMoney = "";
      this.amountSelected = null;
      this.missionRewardType = type;
    },
    editChangeMissionRewardType(type) {
      this.editGetRewardType = type;
    },
    changeReward() {
      this.$store.state.rewardType = !this.$store.state.rewardType;
      if (this.$store.state.rewardType) {
        this.getRewardType = "points";
        this.miniMissionMoney = "0";
      } else {
        this.getRewardType = "money";
        this.miniMissionMoney = "";
      }
    },
    changeRewardVisibility() {
      this.isRewardVisible = !this.isRewardVisible;
    },
    editChangeRewardVisibility() {
      this.editIsRewardVisible = !this.editIsRewardVisible;
    },
    changeMissionHoursType() {
      this.missionHoursType = !this.missionHoursType;
      if (!this.missionHoursType) {
        this.mission_start_hour = "00:00";
        this.mission_end_hour = "23:59";
      } else {
        this.mission_start_hour = "00:00";
        this.mission_end_hour = "23:59";
      }
    },
    changeOwnTimeType() {
      this.ownCustomTime = !this.ownCustomTime;
      if (!this.ownCustomTime) {
        this.mission_start_hour = "00:00";
        this.mission_end_hour = "23:59";
      } else {
        this.mission_start_hour = "00:00";
        this.mission_end_hour = "23:59";
      }
    },
    editChangeMissionHoursType() {
      const editStartTime = this.edit_mission_start_hour_switch;
      const editEndTime = this.edit_mission_end_hour_switch;

      this.editMissionHoursType = !this.editMissionHoursType;
      if (this.editMissionHoursType) {
        this.edit_mission_start_hour = "00:00";
        this.edit_mission_end_hour = "23:59";
      } else {
        this.edit_mission_start_hour = editStartTime;
        this.edit_mission_end_hour = editEndTime;
      }
    },
    openMiniEdit(mission) {
      this.sheetMissionDetails = mission;
      this.openSheetMissionEdit();
      this.addError = "";
      this.editMiniMissionId = mission.id;
      this.editMiniMissionChildId = mission.child_id;
      this.editMiniMissionTitle = mission.name;
      this.editMiniMissionInstructions = mission.description;
      this.editMiniMissionMoney = Number(mission.sum_money).toFixed();
      this.editGetRewardType = mission.reward_type;
      this.editSelectedWishlistItem = mission.product_id;
      this.edit_mission_start_hour_switch =
        mission.date_times_mission[0].start_time;
      this.edit_mission_end_hour_switch =
        mission.date_times_mission[0].end_time;
      this.editIsRewardVisible = mission.is_reward_visible;

      if (
        mission.date_times_mission[0].start_time == "00:00:00" &&
        mission.date_times_mission[0].end_time == "23:59:00"
      ) {
        this.editMissionHoursType = true;
      } else {
        this.editMissionHoursType = false;
        this.edit_mission_start_hour = mission.date_times_mission[0].start_time;
        this.edit_mission_end_hour = mission.date_times_mission[0].end_time;
      }

      this.editMissionDates = {};

      const editCalendarDates = [];

      mission.date_times_mission.forEach(function (date) {
        if (date.start_date) {
          const dateStructure = {
            date: date.start_date,
            start_hour: date.start_time,
            end_hour: date.end_time,
          };
          editCalendarDates.push(dateStructure);
        }
      });

      this.editMissionDates = editCalendarDates;
    },
    closeMiniEdit() {
      this.editMiniModal = false;
      this.editCalendarData = {};
      this.forceRerender();
      this.editMissionShowInputs = true;
      this.editCalendarToogleButtonState = false;
      this.modalHasError = false;
      this.addError = "";
    },

    /* Changing start time input method */
    getScheduleStartTimeCalendar(event) {
      this.mission_start_hour = event;
      console.log(event);
    },

    /* Changing end time input method */
    getScheduleEndTimeCalendar(event) {
      this.mission_end_hour = event;
    },

    editGetScheduleStartTimeCalendar(event) {
      this.edit_mission_start_hour = event;
    },
    editGetScheduleEndTimeCalendar(event) {
      this.edit_mission_end_hour = event;
    },
    childActivate(id, username, balance, avatar) {
      this.childUsername = username;
      this.childAvatar = avatar;
      this.activeChild = id;
      this.$store.state.allMissions = [];
      this.fetchMiniMissionsNew(id);
      this.fetchMissionWishlistAction(id);
    },
    confirmSchedule() {
      if (this.time_conditions.length > 0) {
        this.finalTimesConditions = this.time_conditions;
        this.hideSchedule = true;
        this.confirmScheduleName = "Edit schedule!";
        this.confirmScheduleColor = "red";
      } else {
        this.addError = "Complete weekend schedule if you select daily!";
      }
    },
    editSchedule() {
      this.hideSchedule = false;
      this.confirmScheduleName = "Confirm schedule!";
      this.confirmScheduleColor = "blue";
    },
    deleteMission(id) {
      swal({
        title: "Are you sure?",
        text: "This mission will be deleted!",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.closeSheetMissionDetails();
          this.deleteMiniMission(id);
        }
      });
    },
    addMiniMissionSubmit() {
      if (this.miniMissionTitle.length <= 2) {
        swal({
          title: "Oops...",
          text: "Please complete all the required fields.",
          dangerMode: true,
        });
      } else if (Object.keys(this.calendarData).length === 0) {
        swal({
          title: "Select a date!",
          text: "Please select one or more dates from calendar.",
          dangerMode: true,
        });
      } else if (this.missionRewardType == "") {
        swal({
          title: "Select a reward!",
          text: "Please select a reward type to continue.",
          dangerMode: true,
        });
      } else if (
        this.missionRewardType == "wishlist" &&
        this.selectedWishlistItem == null
      ) {
        swal({
          title: "Select an item!",
          text: "You need to select a wishlist item.",
          dangerMode: true,
        });
      } else if (
        (this.missionRewardType == "money" && this.miniMissionMoney == 0) ||
        (this.missionRewardType == "money" && this.miniMissionMoney == "")
      ) {
        swal({
          title: "Oops...",
          text: "Reward sum cannot be 0 or empty.",
          dangerMode: true,
        });
      } else {
        this.modalHasError = false;
        this.addError = "";
        this.returnDate();
        const { miniMissionTitle, miniMissionInstructions, miniMissionMoney } =
          this;

        if (Object.keys(this.dateCalendar).length > 0) {
          // console.log("time_condition", this.dateCalendar);
          if (this.missionRewardType == "money") {
            if (miniMissionTitle && miniMissionMoney) {
              const mission = {
                categoryId: 1,
                name: miniMissionTitle,
                description: miniMissionInstructions,
                sum_money: miniMissionMoney,
                sum_points: 25,
                reward_type: "money",
                child_id: this.activeChild,
                time_condition: this.dateCalendar,
                is_reward_visible: this.isRewardVisible,
              };

              this.addMiniMission(mission);
              this.closeBottom();
              this.clearAddMission();
            }
          } else if (this.missionRewardType == "wishlist") {
            if (miniMissionTitle) {
              const mission = {
                categoryId: 1,
                name: miniMissionTitle,
                description: miniMissionInstructions,
                sum_money: 0,
                sum_points: 25,
                reward_type: "wishlist",
                product_id: this.selectedWishlistItem.id,
                child_id: this.activeChild,
                time_condition: this.dateCalendar,
                is_reward_visible: this.isRewardVisible,
              };

              this.addMiniMission(mission);
              this.closeBottom();
              this.clearAddMission();
            }
          } else if (this.missionRewardType == "points") {
            if (miniMissionTitle) {
              const mission = {
                categoryId: 1,
                name: miniMissionTitle,
                description: miniMissionInstructions,
                sum_money: 0,
                sum_points: 25,
                reward_type: "points",
                child_id: this.activeChild,
                time_condition: this.dateCalendar,
                is_reward_visible: this.isRewardVisible,
              };

              this.addMiniMission(mission);
              this.closeBottom();
              this.clearAddMission();
            }
          }
        } else {
          swal({
            title: "Select a date!",
            text: "Please select one or more dates from calendar.",
            dangerMode: true,
          });
        }
      }
    },
    editMiniMission() {
      if (this.editMiniMissionTitle.length <= 2) {
        this.modalHasError = true;
        this.addError = "Complete all fields.";
        swal({
          title: "Oops...",
          text: "Please complete all the fields.",
          dangerMode: true,
        });
      } else if (
        (this.editGetRewardType == "money" && this.editMiniMissionMoney == 0) ||
        (this.editGetRewardType == "money" && this.editMiniMissionMoney == "")
      ) {
        swal({
          title: "Oops...",
          text: "Reward sum cannot be 0 or empty.",
          dangerMode: true,
        });
      } else if (
        this.editGetRewardType == "money" &&
        this.$store.state.balance < this.editMiniMissionMoney
      ) {
        swal({
          title: "Oops...",
          text: "You do not have enough funds.",
          dangerMode: true,
        });
      } else {
        const finalTimestamps = [];
        const startHour = this.edit_mission_start_hour;
        const endHour = this.edit_mission_end_hour;

        this.editMissionDates.forEach(function (value) {
          const datesArr = {
            start_datetime: value.date + " " + startHour,
            end_datetime: value.date + " " + endHour,
          };
          finalTimestamps.push(datesArr);
        });

        var self = this;
        if (Object.keys(this.editCalendarData).length > 0) {
          if (this.editCalendarData.selectedDates.length > 0) {
            this.editReturnDate();
            this.editCalendarData.selectedDates.forEach(function (value) {
              const datesArr2 = {
                start_datetime: value.date + " " + startHour,
                end_datetime: value.date + " " + endHour,
              };

              //Daca se selecteaza aceasi data cu data existenta, nu se mai face push in array sa se trimita la server
              const filterDuplicateDates = self.editMissionDates.find(
                (item) => item.date == value.date
              );
              filterDuplicateDates == undefined
                ? finalTimestamps.push(datesArr2)
                : null;
            });
          }
        }

        const {
          editMiniMissionTitle,
          editMiniMissionInstructions,
          editMiniMissionMoney,
        } = this;

        if (this.editGetRewardType == "money") {
          if (editMiniMissionTitle && editMiniMissionMoney) {
            const mission = {
              categoryId: 1,
              mission_id: this.editMiniMissionId,
              name: editMiniMissionTitle,
              description: editMiniMissionInstructions,
              sum_money: editMiniMissionMoney,
              sum_points: 25,
              reward_type: "money",
              time_condition: finalTimestamps,
              reward_hidden: this.editIsRewardVisible,
            };
            // console.log(mission);
            this.editMission(mission);
            this.editMiniMissionId = null;
            this.editMiniMissionTitle = "";
            this.editMiniMissionInstructions = "";
            this.editMiniMissionMoney = "";
            this.editCalendarData = {};
            this.modalHasError = false;
            this.addError = "";
            this.closeSheetMissionEdit();
          }
        } else if (this.editGetRewardType == "wishlist") {
          if (editMiniMissionTitle && this.missionWishlistGetter.length > 0) {
            const mission = {
              categoryId: 1,
              mission_id: this.editMiniMissionId,
              name: editMiniMissionTitle,
              description: editMiniMissionInstructions,
              sum_money: 0,
              sum_points: 25,
              reward_type: "wishlist",
              product_id: this.editSelectedWishlistItem,
              time_condition: finalTimestamps,
              reward_hidden: this.editIsRewardVisible,
            };

            this.editMission(mission);
            this.editMiniMissionId = null;
            this.editMiniMissionTitle = "";
            this.editMiniMissionInstructions = "";
            this.editMiniMissionMoney = "";
            this.editCalendarData = {};
            this.modalHasError = false;
            this.addError = "";
            this.closeSheetMissionEdit();
          } else {
            swal({
              title: "Oops...",
              text: "You selected Wishlist as reward type, but there are no wishlist items selected. Please choose another reward type.",
              dangerMode: true,
            });
          }
        } else if (this.editGetRewardType == "points") {
          if (editMiniMissionTitle) {
            const mission = {
              categoryId: 1,
              mission_id: this.editMiniMissionId,
              name: editMiniMissionTitle,
              description: editMiniMissionInstructions,
              sum_money: 0,
              sum_points: 25,
              reward_type: "points",
              time_condition: finalTimestamps,
              reward_hidden: this.editIsRewardVisible,
            };
            this.editMission(mission);
            this.editMiniMissionId = null;
            this.editMiniMissionTitle = "";
            this.editMiniMissionInstructions = "";
            this.editMiniMissionMoney = "";
            this.editCalendarData = {};
            this.modalHasError = false;
            this.addError = "";
            this.closeSheetMissionEdit();
          }
        }
      }
    },
    resolveImage(src) {
      return config.HOST + src;
    },
    openPastMissionsModal() {
      this.fetchMiniMissionsNew(this.activeChild);
      this.fetchProfile();
      this.$store.state.pastMissionsModal = true;
    },
    closePastMissionsModal() {
      this.$store.state.pastMissionsModal = false;
    },
  },
  watch: {
    editGetRewardType: function (val) {
      if (val == "wishlist") {
        // console.log(this.editMiniMissionChildId);
        this.fetchMissionWishlistAction(this.editMiniMissionChildId);
      }
    },
    miniMissionMoney: function (val) {
      if (val > this.$store.state.balance) {
        this.noFundsError = true;
        this.noFundsMessage =
          "You have not enough funds to reward this mission!";
        this.finishStepVal = true;
      } else {
        this.noFundsError = false;
        this.noFundsMessage = "";
        this.finishStepVal = false;
      }
    },
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  margin-bottom: 20px;
}

.stories {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  overflow: auto hidden;
  margin-top: 10px;
}

.stories__item {
  user-select: none;
  margin-right: 15px;
}

.stories__item button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: 0;
}

.stories__item-picture {
  width: 55px;
  height: 55px;
  position: relative;
}

.stories__item-picture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  pointer-events: none;
}

.stories__item-username {
  color: #01a3c038;
  border: 2px solid #01a3c038;
  border-radius: 60px;
  padding: 10px;
  padding-inline: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.stories__item-username--active {
  color: #01a4c0;
  border-color: #01a4c0;
}

.stories__item--active .stories__item-picture::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  flex-direction: row;
}

#missions {
  margin: 30px 0;

  .family_container {
    margin-top: 20px;
    background: white;
    padding: 25px;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .family_text {
    color: var(--ternary);

    &__title {
      font-weight: bold;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.03rem;
      color: var(--main);
    }

    &__amount {
      flex-direction: row;

      color: var(--main);
      line-height: 20px;
    }
  }

  .family_button {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    background: var(--main);
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
      opacity: 0.8;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.noMissions {
  color: var(--secondary);
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  margin: 20px 0;

  h1 {
    letter-spacing: 0.5px;
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }
}

.updateChildButton {
  background: var(--red);
  border: none;
  width: 100%;
  color: white;
  padding: 15px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 30px;
  cursor: pointer;
}

.addChildButton {
  background: #f3c85a;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    opacity: 0.8;
  }

  svg {
    width: 25px;
    height: 25px;
  }
}

#recent {
  // h1 {
  //   font-weight: 600;
  //   font-size: 15px;
  //   letter-spacing: 0.02rem;
  //   text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  // }

  .recent_container {
    margin-top: 20px;
  }

  .activity_item {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    transition: all 200ms ease-in-out !important;
    background-color: #f6a42c;
    border: none;

    &:active {
      opacity: 0.6;
    }
    &__box {
    }
    &__container {
      display: flex;

      align-items: center;
    }

    &__title {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.02rem;
      white-space: nowrap;
      overflow: hidden;
      // text-overflow: ellipsis;
      color: #ffffff;
    }

    &__description {
      font-size: 14px;
      color: #ffffff;
      white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      margin-top: 5px;
    }

    &__amount {
      // position: absolute;
      top: 5px;
      // right: 15px;
      color: var(--main);
      font-weight: 600;
      font-size: 11px;
    }

    &__icon {
      margin-right: 15px;
      display: flex;
      align-items: center;

      img {
        width: 50px;
      }
    }
  }
}
.reward {
  font-size: 30px;
  margin-top: 5px;
}

.selectedAmount {
  background: #56b897;
  border-color: #56b897;
  color: white;
}

.reward-type-background {
  &__points {
    background: #0d4f74 !important;
  }
  &__money {
    background: #56b897 !important;
  }
  &__wishlist {
    background: #ec6c6b !important;
  }
}
.card {
  background: #f5f5f5;
  margin: 5px;
  width: 45%;
  border-radius: 30px;
  font-size: 36px;
  font-weight: 800;
}
#upcoming {
  color: #9e9e9e;
}

#in-progress {
  color: white;
  background-color: #01a4c0;
}

#pending {
  color: white;
  background-color: #f6a42c;
}

#recent {
  color: #01a4c0;
}

#history {
  color: #01a4c0;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  padding-bottom: 30px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  height: 0;
}

.card-text {
  font-size: 16px;
  text-align: right;
  padding: 20px;
  font-weight: 500;
}
.header-history {
  padding-top: 30px;
  padding-bottom: 30px;
}

#new-mission {
  color: #01a4c0;
  font-size: 16px;
  font-weight: 700;
  // border-radius: 40px;
  // padding: 25px;
  // padding-left: 90px;
  // padding-right: 90px;
}

.new-mission-div {
  background-color: #01a4c0;
  margin: 5px;
  width: 45%;
  border-radius: 30px;
  height: 100px;
}

#add-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
}
.sub-title {
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  color: #806157;
}
.active_reward {
  color: #01a4c0 !important;
}

.view_more {
  background-color: #01a4c0;
  color: white;
  margin: 60px;
  margin-top: 20px;
  border-radius: 30px;
  padding: 10px;
}
.add-button {
  background-color: #01a4c0;
  color: white;
  border-radius: 69px;
  width: 33px;
  height: 33px;
  font-size: 20px;
  font-weight: bold;
}
</style>
